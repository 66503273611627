import React, { Component } from 'react';

import {
    Link
} from "react-router-dom";
import withNavigateHook from './withNavigateHook';
class Header extends Component {

    /**
    * 
    * @param {*} props 
    */
    constructor(props) {
        super(props);
        this.action = window.location.pathname.split("/").pop();
        //console.log(this.action);
    }
    render() {

        return (
            <React.Fragment>
                <nav className='menu-header-bg'>
                    <div className="nav-wrapper">

                        <a href="#!" className="brand-logo pl-20" >
                            <img className="responsive-img logoImg" src="images/mul-logo-bg.png" />
                        </a>
                        <a href="#" data-target="mobile-demo" className="sidenav-trigger">
                            <i className="material-icons">menu</i>
                        </a>
                        <ul className="right hide-on-med-and-down">
                            <li><a href="#">Home</a></li>
                            <li><a href="#">Inventory</a></li>
                            <li><a href="#">Room Rate</a></li>
                            <li><a href="#">Analytics</a></li>
                        </ul>

                    </div>
                </nav>
            </React.Fragment>
        );
    }
}

export default withNavigateHook(Header);
