// App.js
import React from 'react';
import Navigation from './components/Navigation';


function App() {
  return (
    <React.Fragment>
      <Navigation />
    </React.Fragment>
  );
}

export default App;
