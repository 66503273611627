
/* eslint-disable */
import React, { Component } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './Home';
import App from '../App';

export default class Navigation extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount = () => {}

    /**
     * Setting the Routing for the Application
     * @returns 
     */
    render() {
        return (
            <React.Fragment>
                <BrowserRouter basename="/">
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/home" element={<Home />} />
                    </Routes>
                </BrowserRouter>
            </React.Fragment>
        );
    }
}

