import React, { Component } from 'react'
import withNavigateHook from './withNavigateHook'
import Sidebar from './Sidebar'
import Header from './Header'
import {
  Link
} from "react-router-dom";
import CalendarComponent from './CalendarComponent';
class Home extends Component {
  /**
     * 
     * @returns 
     */
  render() {

    return (
      <React.Fragment>

        {/* <Loader visibility={this.state.loader} /> */}
        <Header />
        <Sidebar />
        <main role="main">

          <section id="welcomePage" className="oparcheecommonCode">
         
            <div className="welcomePageContent">

              <div className="container">

                <div className="row">

                  <div className="col s12">


                    <div className='white-bg center-align commonBoxInner m-center'>

                      <CalendarComponent />
                      
                      
                    </div>
                     

                  </div>



                </div>

              </div>

            </div>

          </section>

        </main>
      </React.Fragment>
    );
  }
}

export default withNavigateHook(Home)

