// CalendarComponent.js
import React from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
 

const CalendarComponent = () => {
  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-md-10">
          <FullCalendar
            plugins={[dayGridPlugin]}
            initialView="dayGridMonth"
            events={[
              { title: 'Event 1', date: '2024-10-15' },
              { title: 'Event 2', date: '2024-10-16' },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default CalendarComponent;
